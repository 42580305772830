import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormFieldValueValidator from './form-field-value-validator';
import FormFieldValueValidatorDetail from './form-field-value-validator-detail';
import FormFieldValueValidatorUpdate from './form-field-value-validator-update';
import FormFieldValueValidatorDeleteDialog from './form-field-value-validator-delete-dialog';

const FormFieldValueValidatorRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FormFieldValueValidator />} />
    <Route path="new" element={<FormFieldValueValidatorUpdate />} />
    <Route path=":id">
      <Route index element={<FormFieldValueValidatorDetail />} />
      <Route path="edit" element={<FormFieldValueValidatorUpdate />} />
      <Route path="delete" element={<FormFieldValueValidatorDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormFieldValueValidatorRoutes;
