/* export const CustomSpeechForm = () => {
  let recognition;
  let isRecognizing = false;
  let result = '';
  let formClass = '';

  function startRecognition() {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)(); // For Chrome, use webkitSpeechRecognition
      console.log('Enter in SpeechRecognition', recognition);
      recognition.lang = 'en-US';
      recognition.continuous = true;

      recognition.onstart = function () {
        console.log('Enter recognition.onstart');
        isRecognizing = true;
        document.getElementById('toggleButton').innerText = 'Stop Speech';
      };

      recognition.onresult = function (event) {
        result = event.results[event.resultIndex][0].transcript;
        //   console.log('You said: ' + result);
        getDataFromApi(result);
      };

      recognition.onend = function () {
        isRecognizing = false;
        document.getElementById('toggleButton').innerText = 'Speech Here';
      };

      recognition.start();
    }
  }

  function stopRecognition() {
    recognition.stop();
    // console.log(result, 'resultresultp');
  }

  function toggleRecognition(e) {
    // fillFormData(tempData, "voice_recog_button_form_1")
    if (isRecognizing) {
      stopRecognition();
    } else {
      //   console.log();
      console.log('Enter before');
      startRecognition();
      console.log('Exit');
      formClass = e.target.classList[0];
    }
  }
  function getFormFields(forms) {
    const fields = [];
    Array.from(forms).forEach((element, index) => {
      // Get all visible form elements
      fields[`voice_recog_button_form_${index + 1}`] = {};
      const visibleElements = Array.from(element.elements).filter(item => {
        return item.offsetParent !== null; // Check if the element is visible
      });
      //   console.log(fields, 'fields');
      // Extract field names
      visibleElements.forEach(item => {
        if (item.name) {
          fields[`voice_recog_button_form_${index + 1}`][item.name] = '';
        }
      });
    });
    return fields;
  }
  function getDataFromApi(prompt) {
    // console.log({ ...formFields[formClass] });
    const currentForm = { ...formFields[formClass] };
    const formFieldsData = [];
    Object.keys(currentForm).forEach(item => {
      formFieldsData.push({ name: item });
    });
    // URL of the API endpoint
    const apiUrl = 'https://formwizard.test6.redblink.net/api/aiform/fill';

    // Data to be sent in the POST request (replace this with your actual data)
    const postData = { formFields: [...formFieldsData], prompt };

    // Configure the fetch options
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Specify the content type as JSON
        // Add any other headers as needed
      },
      body: JSON.stringify(postData), // Convert data to JSON format
    };

    // Make the POST request using fetch
    fetch(apiUrl, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Assuming the response is in JSON format
      })
      .then(data => {
        // Process the response data
        // console.log('Response:', data);
        fillFormData(data, formClass);
        // alert(data)
      })
      .catch(error => {
        // Handle errors during the fetch
        alert(error);
        // console.error('Error:', error);
      });
  }

  function fillFormData(data, form1) {
    const form = document.getElementsByClassName(form1)[0];
    const formValues = data.formFieldSubmissionValues;
    formValues.forEach((item, index) => {
      //   console.log(item);
      if (item.value !== null) {
        form.elements[`${item.fieldName}`].value = item.value;
      }
    });
  }
  const allForms = document.getElementsByTagName('form'); // get all forms on page
  const visibleForms = Array.from(allForms).filter(item => {
    return item.offsetParent !== null;
  });
  const formFields = getFormFields(visibleForms);
  Array.from(visibleForms).forEach((item, index) => {
    const divOuter = document.createElement('div');
    // divOuter.classList.add(`voice_recog_form_outer_${index + 1}`)

    // Create a new input element
    const textField = document.createElement('input');

    // Set the type attribute to "text" for a text field
    textField.type = 'text';

    // Optionally, you can set other attributes for the input element, e.g., placeholder, id, etc.
    textField.placeholder = 'Enter text...';

    // Get a reference to the div by its id

    // Append the input element to the div
    divOuter.appendChild(textField);

    const node = document.createElement('button');
    const text = document.createTextNode('Speech Here');
    node.classList.add(`voice_recog_button_form_${index + 1}`);
    node.setAttribute('id', 'toggleButton');
    item.classList.add(`voice_recog_button_form_${index + 1}`);
    node.appendChild(text);
    divOuter.appendChild(node);
    item.appendChild(divOuter);
  });

  document.getElementById('toggleButton').addEventListener('click', function (event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    console.log(event, 'eventevent');
    toggleRecognition(event);
  });
};*/

export const CustomSpeechForm = () => {
  let recognition;
  let isRecognizing = false;
  let result = '';
  let formClass = '';

  function startRecognition() {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)(); // For Chrome, use webkitSpeechRecognition

      recognition.lang = 'en-US';
      recognition.continuous = true;

      const input = document.getElementById(formClass).querySelector('input');
      const speechButton = document.getElementById(formClass).querySelector('.speech_button');
      const submitButton = document.getElementById(formClass).querySelector('.voice_recog_submit');

      recognition.onstart = function () {
        isRecognizing = true;
        input.value = '';
        speechButton.innerText = 'Stop Speech';
        input.disabled = true;
        submitButton.disabled = true;
      };

      recognition.onresult = function (event) {
        result = event.results[event.resultIndex][0].transcript;
        input.value = result;
      };

      recognition.onend = function () {
        isRecognizing = false;
        speechButton.innerText = 'Speech Here';
        input.disabled = false;
        submitButton.disabled = false;
      };

      recognition.start();
    }
  }

  function stopRecognition() {
    recognition.stop();
  }

  function submitRecog(params) {
    getDataFromApi(params.target.parentNode.firstChild.value);
  }

  function getDataFromApi(prompt) {
    // console.log({ ...formFields[formClass] });
    const currentForm = { ...formFields[formClass] };
    const formFieldsData = [];
    Object.keys(currentForm).forEach(item => {
      formFieldsData.push({ name: item });
    });
    // URL of the API endpoint
    const apiUrl = 'https://formwizard.test6.redblink.net/api/aiform/fill';

    // Data to be sent in the POST request (replace this with your actual data)
    const postData = { formFields: [...formFieldsData], prompt };
    // Configure the fetch options
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Specify the content type as JSON
        // Add any other headers as needed
      },
      body: JSON.stringify(postData), // Convert data to JSON format
    };

    // Make the POST request using fetch
    fetch(apiUrl, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Assuming the response is in JSON format
      })
      .then(data => {
        // Process the response data
        console.log('Response:', data);
        fillFormData(data, formClass);
        // alert(data)
      })
      .catch(error => {
        // Handle errors during the fetch
        alert(error);
        console.error('Error:', error);
      });
  }

  function fillFormData(data, form1) {
    // console.log(data, form1,"data, form1data, form1");
    const form = document.getElementsByClassName(form1)[0];
    console.log(form, 'formform');
    const formValues = data.formFieldSubmissionValues;
    formValues.forEach((item, index) => {
      console.log(item);
      if (item.value !== null) {
        form.elements[`${item.fieldName}`].value = item.value;
      }
    });
  }

  const allForms = document.getElementsByTagName('form'); // get all forms on page

  // get all forms whose are visible on page
  const visibleForms = Array.from(allForms).filter(item => {
    return item.offsetParent !== null;
  });

  // get form fields
  function getFormFields(forms) {
    const fields = [];
    Array.from(forms).forEach((element, index) => {
      // Get all visible form elements
      fields[`voice_recog_form_${index + 1}`] = {};
      const visibleElements = Array.from(element.elements).filter(item => {
        return item.offsetParent !== null; // Check if the element is visible
      });
      // Extract field names
      visibleElements.forEach(item => {
        if (item.name) {
          fields[`voice_recog_form_${index + 1}`][item.name] = '';
        }
      });
    });
    return fields;
  }

  function toggleRecognition(e) {
    if (isRecognizing) {
      stopRecognition();
    } else {
      startRecognition();
    }
  }

  const formFields = getFormFields(visibleForms);

  Array.from(visibleForms).forEach((item, index) => {
    const divOuter = document.createElement('div');

    const textField = document.createElement('input');

    textField.type = 'text';

    textField.placeholder = 'Enter text...';

    divOuter.appendChild(textField);

    const node = document.createElement('button');

    const text = document.createTextNode('Submit');

    node.classList.add(`voice_recog_form_${index + 1}`, 'voice_recog_submit');

    // node.setAttribute('id', 'voice_recog_submit');
    const speechNode = document.createElement('button');
    const speechNodeText = document.createTextNode('Speech Here');

    speechNode.classList.add(`speech_button`);

    speechNode.appendChild(speechNodeText);
    item.classList.add(`voice_recog_form_${index + 1}`);
    divOuter.setAttribute('id', `voice_recog_form_${index + 1}`);

    node.appendChild(text);

    divOuter.appendChild(speechNode);
    divOuter.appendChild(node);

    item.appendChild(divOuter);
  });

  Array.from(document.getElementsByClassName('voice_recog_submit')).forEach((item, index) => {
    item.addEventListener('click', function (event) {
      event.stopImmediatePropagation();
      event.preventDefault();

      formClass = event.target.parentNode.id;
      submitRecog(event);
    });
  });

  Array.from(document.getElementsByClassName('speech_button')).forEach((item, index) => {
    item.addEventListener('click', function (event) {
      event.stopImmediatePropagation();
      event.preventDefault();
      formClass = event.target.parentNode.id;
      toggleRecognition(event);
    });
  });

  // document.getElementById('toggleButton').addEventListener('click', function (event) {
  //     event.stopImmediatePropagation();
  //     event.preventDefault();
  //     console.log(event, 'eventevent');
  //     formClass = event.target.classList[0];
  //     submitRecog(event);
  // });
};
