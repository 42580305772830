import React, { useEffect, useId, useRef, useState } from 'react';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { createEntity, createForm, sendData, formFill } from './dashboard.reducer';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { IFormField } from 'app/shared/model/form-field.model';
import PendingFieldModal from './pending-field-modal';
import { Wizard, useWizard } from 'react-use-wizard';
import FormSection from './formSection';

const PublicDashboard = () => {
  const id = useId();
  const dispatch = useAppDispatch();
  let location = useLocation();

  const [promptId, setPromptId] = useState<number>();

  const formTitle = location.pathname.replace('/viewform/', '');

  const [loading, setLoading] = useState(true);
  const [formSection, setFormSection] = useState<any>({});
  const [fields, setFields] = useState<any>({});

  useEffect(() => {
    if (loading) {
      dispatch(createForm(formTitle)).then(({ payload: { data } }) => {
        setFormSection(data);
        let tempData = {};
        data.formFields.map(item => {
          tempData[item.name] = null;
        });
        setFields({ ...tempData });
        setPromptId(data.id);
        setLoading(false);
      });
    }
  }, []);

  const saveEntity = () => {
    const entity = {
      ...fields,
    };

    dispatch(createEntity(entity));
  };

  return (
    <>
      <Container className="mt-5">
        <Card>
          <Wizard>
            {formSection &&
              formSection?.formSections &&
              formSection.formSections.length > 0 &&
              formSection.formSections.map((item, index) => {
                return (
                  <FormSection
                    saveEntity={saveEntity}
                    setFields={setFields}
                    fields={fields}
                    section={item}
                    id={id}
                    promptId={promptId}
                    loading={loading}
                    formTitle={formTitle}
                  />
                );
              })}
            {/* <FormSection /> */}
          </Wizard>
        </Card>
      </Container>
    </>
  );
};

export default PublicDashboard;
