import dayjs from 'dayjs';
import { IFormSubmission } from 'app/shared/model/form-submission.model';

export interface IFormSubmissionInvite {
  id?: number;
  email?: string | null;
  businessIdentifier?: string | null;
  phone?: string | null;
  validUntil?: string | null;
  gracePeriod?: number | null;
  formSubmission?: IFormSubmission | null;
}

export const defaultValue: Readonly<IFormSubmissionInvite> = {};
