import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormField from './form-field';
import FormFieldDetail from './form-field-detail';
import FormFieldUpdate from './form-field-update';
import FormFieldDeleteDialog from './form-field-delete-dialog';

const FormFieldRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FormField />} />
    <Route path="new" element={<FormFieldUpdate />} />
    <Route path=":id">
      <Route index element={<FormFieldDetail />} />
      <Route path="edit" element={<FormFieldUpdate />} />
      <Route path="delete" element={<FormFieldDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormFieldRoutes;
