import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ValueSuggestor from './value-suggestor';
import ValueSuggestorDetail from './value-suggestor-detail';
import ValueSuggestorUpdate from './value-suggestor-update';
import ValueSuggestorDeleteDialog from './value-suggestor-delete-dialog';

const ValueSuggestorRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ValueSuggestor />} />
    <Route path="new" element={<ValueSuggestorUpdate />} />
    <Route path=":id">
      <Route index element={<ValueSuggestorDetail />} />
      <Route path="edit" element={<ValueSuggestorUpdate />} />
      <Route path="delete" element={<ValueSuggestorDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ValueSuggestorRoutes;
