import { IFormFieldValueValidator } from 'app/shared/model/form-field-value-validator.model';

export interface IValueValidator {
  id?: number;
  name?: string | null;
  implementation?: string | null;
  inputs?: string | null;
  formFieldValueValidators?: IFormFieldValueValidator[] | null;
}

export const defaultValue: Readonly<IValueValidator> = {};
