import React, { useEffect, useId, useRef, useState } from 'react';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, Card, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { createEntity, createForm, sendData, formFill } from './dashboard.reducer';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { IFormField } from 'app/shared/model/form-field.model';
import PendingFieldModal from './pending-field-modal';

const Dashboard = () => {
  const id = useId();
  const dispatch = useAppDispatch();
  let location = useLocation();
  const [promptId, setPromptId] = useState<number>();
  const params = new URLSearchParams(location.search);

  const formTitle = params.get('formTitle');
  const [buttonLoading, setButtonLoading] = useState(false);
  const resultForm = useRef();
  // const [listening, setListening] = useState(false);
  const [dynamicForm, setDynamicForm] = useState<IFormField | any>([]);
  const [steps, setSteps] = useState([]);
  const [dynamicFormData, setDynamicFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [resultValue, setResultValue] = useState('');
  const [pendingFields, setPendingFields] = useState([]);
  const [popUpFieldsValues, setPopUpFieldsValues] = useState<any>({});
  const formEntity = useAppSelector(state => state.form.entity);

  const [textToCopy, setTextToCopy] = useState<any>();

  useEffect(() => {
    console.log(popUpFieldsValues, 'popUpFieldsValuespopUpFieldsValues');
  }, [popUpFieldsValues]);
  useEffect(() => {
    if (loading) {
      dispatch(createForm(formTitle)).then(({ payload: { data } }) => {
        console.log(data, 'data');

        setPromptId(data.id);
        setDynamicForm(data.formFields);

        let tempData = {};
        data.formFields.map(item => {
          tempData[item.name] = '';
        });
        setDynamicFormData(tempData);

        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    console.log(dynamicFormData, 'dynamicFormDatadynamicFormData');
  }, [dynamicFormData]);

  const startListening = () => SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
  const { transcript, interimTranscript, finalTranscript, resetTranscript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    setResultValue(transcript);
  }, [transcript]);

  useEffect(() => {
    console.log(`%c Browser is ${listening ? 'Listening' : 'not Listening'}`, 'color:red; font-size:20px');
  }, [listening]);

  if (!browserSupportsSpeechRecognition) {
    console.log('Browser not supporting!');
    // return null;
  }

  // Getting data from backend on the basis of prompt and set pending fields for modal ------ Start
  const resultHandle = async () => {
    setButtonLoading(true);
    await dispatch(sendData({ userPrompt: resultValue, promptId: promptId })).then(({ payload }) => {
      let tempData = {};
      payload.formFieldSubmissionValues.map(item => {
        tempData[item.fieldName] = item.value;
      });

      let filteredArray = dynamicForm.filter(item => {
        // Check if each property in filterObject matches the corresponding property in the array item
        for (let key in tempData) {
          if (tempData.hasOwnProperty(key) && item.name == key) {
            return false;
          }
        }

        return true;
      });
      setPendingFields([...filteredArray]);
      let tempFields = [];
      if (filteredArray && filteredArray.length > 0) {
        filteredArray.forEach((item, index) => {
          tempFields.push({
            key: `step_${index}`,
            label: `My ${index} step`,
            isDone: true,
            component: (
              <ValidatedField
                label={item.prompt}
                id={`${id}-${item.name}`}
                onChange={e => {
                  console.log(e, 'e,re,r');
                  setPopUpFieldsValues(prev => {
                    return {
                      ...prev,
                      [e.target.name]: e.target.value,
                    };
                  });
                }}
                name={item.name}
                data-cy="title"
                type="text"
                required={item.isRequired}
              />
            ),
          });
        });
      }
      setSteps([...tempFields]);
      setModalOpen(true);

      setDynamicFormData(tempData);
      setButtonLoading(false);
    });
  };
  // Getting data from backend on the basis of prompt and set pending fields for modal ------ End

  const formSubmitHandle = values => {
    setDynamicFormData(prevValue => {
      return {
        ...prevValue,
        ...values,
      };
    });
  };

  const saveEntity = values => {
    const entity = {
      ...formEntity,
      ...values,
    };
    dispatch(createEntity(entity));
  };

  const resultValueHandle = e => {
    setResultValue(e.target.value);
  };

  const modalHandle = () => {
    setModalOpen(!modalOpen);
  };

  const convertToTitleCase = inputString => {
    // Split the input string into an array of words
    let words = inputString.split(/(?=[A-Z])/);

    // Capitalize the first letter of each word
    let titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words with a space to form the final title case string
    let titleCaseString = titleCaseWords.join(' ');

    return titleCaseString;
  };

  return (
    <>
      <Container>
        <Card>
          <h2>Good Morning</h2>
          <h4>Welcome to {convertToTitleCase(formTitle)}</h4>
          <ValidatedForm onSubmit={resultHandle}>
            <ValidatedField
              label="Result"
              id={`${id}-result`}
              disabled={listening}
              name="result"
              data-cy="title"
              onChange={resultValueHandle}
              value={resultValue}
              type="textarea"
            />
            <Button
              color="info"
              disabled={listening || buttonLoading}
              onClick={() => {
                // setListening(true);
                setResultValue('');
                resetTranscript();
                startListening();
              }}
            >
              {buttonLoading ? <Spinner size="sm">Loading...</Spinner> : ''}
              <span>Start Listening</span>
            </Button>{' '}
            <Button
              disabled={buttonLoading}
              color="warning"
              onClick={() => {
                // setListening(false);
                SpeechRecognition.stopListening();
                resultHandle();
              }}
            >
              {buttonLoading ? <Spinner size="sm">Loading...</Spinner> : ''}
              <span>Stop Listening</span>
            </Button>{' '}
            <Button color="primary" id="result-entity" data-cy="entityCreateSaveButton" disabled={buttonLoading} type="submit">
              {buttonLoading ? <Spinner size="sm">Loading...</Spinner> : ''}

              <span> Submit</span>
            </Button>
          </ValidatedForm>
          {loading ? (
            <>
              <Spinner
                color="primary"
                style={{
                  height: '3rem',
                  width: '3rem',
                }}
              >
                Loading...
              </Spinner>
            </>
          ) : (
            <div className="mt-3 dynamic-form">
              {dynamicForm && dynamicForm.length && dynamicForm.length > 0 ? (
                <ValidatedForm onSubmit={saveEntity} defaultValues={dynamicFormData}>
                  {dynamicForm.map((item: IFormField, index) => {
                    return (
                      <ValidatedField
                        key={index}
                        label={convertToTitleCase(item.name)}
                        id={`${id}-${item.name}`}
                        name={item.name}
                        data-cy="title"
                        type="text"
                        required={item.isRequired}
                      />
                    );
                  })}
                  {/* <ValidatedField label="Customer" id={`${id}-customer`} name="customer" data-cy="title" type="text" />
                  <ValidatedField label="Commodity" id={`${id}-commodity`} name="commodity" data-cy="title" type="text" />
                  <ValidatedField label="Farm Name" id={`${id}-farmName`} name="farmName" data-cy="title" type="text" />
                  <ValidatedField label="Operation" id={`${id}-operation`} name="operation" data-cy="title" type="text" />
                  <ValidatedField label="Cut Level" id={`${id}-cutLevel`} name="cutLevel" data-cy="title" type="text" />
                  <ValidatedField label="ER/EOR" id={`${id}-er`} name="er" data-cy="title" type="text" /> */}

                  <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
                    Submit
                  </Button>
                </ValidatedForm>
              ) : (
                ''
              )}
            </div>
          )}
        </Card>
        {steps && steps.length > 0 ? (
          <PendingFieldModal
            pendingFields={pendingFields}
            formSubmitHandle={formSubmitHandle}
            setDynamicFormData={setDynamicFormData}
            steps={steps}
            modalHandle={modalHandle}
            modalOpen={modalOpen}
            popUpFieldsValues={popUpFieldsValues}
          />
        ) : (
          ''
        )}
      </Container>
    </>
  );
};

export default Dashboard;
