import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IForm } from 'app/shared/model/form.model';
import { getEntities as getForms } from 'app/entities/form/form.reducer';
import { IFormSectionField } from 'app/shared/model/form-section-field.model';
import { getEntities as getFormSectionFields } from 'app/entities/form-section-field/form-section-field.reducer';
import { IFormSection } from 'app/shared/model/form-section.model';
import { getEntities as getFormSections } from 'app/entities/form-section/form-section.reducer';
import { IFormField } from 'app/shared/model/form-field.model';
import { getEntity, updateEntity, createEntity, reset } from './form-field.reducer';

export const FormFieldUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const forms = useAppSelector(state => state.form.entities);
  const formSectionFields = useAppSelector(state => state.formSectionField.entities);
  const formSections = useAppSelector(state => state.formSection.entities);
  const formFieldEntity = useAppSelector(state => state.formField.entity);
  const loading = useAppSelector(state => state.formField.loading);
  const updating = useAppSelector(state => state.formField.updating);
  const updateSuccess = useAppSelector(state => state.formField.updateSuccess);

  const handleClose = () => {
    navigate('/form-field' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getForms({}));
    dispatch(getFormSectionFields({}));
    dispatch(getFormSections({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...formFieldEntity,
      ...values,
      form: forms.find(it => it.id.toString() === values.form.toString()),
      formSectionField: formSectionFields.find(it => it.id.toString() === values.formSectionField.toString()),
      formSection: formSections.find(it => it.id.toString() === values.formSection.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...formFieldEntity,
          form: formFieldEntity?.form?.id,
          formSectionField: formFieldEntity?.formSectionField?.id,
          formSection: formFieldEntity?.formSection?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="coreAiFormWizardApp.formField.home.createOrEditLabel" data-cy="FormFieldCreateUpdateHeading">
            <Translate contentKey="coreAiFormWizardApp.formField.home.createOrEditLabel">Create or edit a FormField</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="form-field-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('coreAiFormWizardApp.formField.prompt')}
                id="form-field-prompt"
                name="prompt"
                data-cy="prompt"
                type="text"
              />
              <ValidatedField
                label={translate('coreAiFormWizardApp.formField.name')}
                id="form-field-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('coreAiFormWizardApp.formField.validatorInput')}
                id="form-field-validatorInput"
                name="validatorInput"
                data-cy="validatorInput"
                type="text"
              />
              <ValidatedField
                label={translate('coreAiFormWizardApp.formField.suggestorInput')}
                id="form-field-suggestorInput"
                name="suggestorInput"
                data-cy="suggestorInput"
                type="text"
              />
              <ValidatedField
                label={translate('coreAiFormWizardApp.formField.isRequired')}
                id="form-field-isRequired"
                name="isRequired"
                data-cy="isRequired"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('coreAiFormWizardApp.formField.possibleValues')}
                id="form-field-possibleValues"
                name="possibleValues"
                data-cy="possibleValues"
                type="text"
              />
              <ValidatedField
                label={translate('coreAiFormWizardApp.formField.type')}
                id="form-field-type"
                name="type"
                data-cy="type"
                type="text"
              />
              <ValidatedField
                id="form-field-form"
                name="form"
                data-cy="form"
                label={translate('coreAiFormWizardApp.formField.form')}
                type="select"
              >
                <option value="" key="0" />
                {forms
                  ? forms.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="form-field-formSectionField"
                name="formSectionField"
                data-cy="formSectionField"
                label={translate('coreAiFormWizardApp.formField.formSectionField')}
                type="select"
              >
                <option value="" key="0" />
                {formSectionFields
                  ? formSectionFields.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="form-field-formSection"
                name="formSection"
                data-cy="formSection"
                label={translate('coreAiFormWizardApp.formField.formSection')}
                type="select"
              >
                <option value="" key="0" />
                {formSections
                  ? formSections.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/form-field" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FormFieldUpdate;
