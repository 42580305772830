import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormSection from './form-section';
import FormSectionDetail from './form-section-detail';
import FormSectionUpdate from './form-section-update';
import FormSectionDeleteDialog from './form-section-delete-dialog';

const FormSectionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FormSection />} />
    <Route path="new" element={<FormSectionUpdate />} />
    <Route path=":id">
      <Route index element={<FormSectionDetail />} />
      <Route path="edit" element={<FormSectionUpdate />} />
      <Route path="delete" element={<FormSectionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormSectionRoutes;
