import formSubmission from 'app/entities/form-submission/form-submission.reducer';
import form from 'app/entities/form/form.reducer';
import formSection from 'app/entities/form-section/form-section.reducer';
import formField from 'app/entities/form-field/form-field.reducer';
import formFieldSubmissionValue from 'app/entities/form-field-submission-value/form-field-submission-value.reducer';
import formSectionField from 'app/entities/form-section-field/form-section-field.reducer';
import valueValidator from 'app/entities/value-validator/value-validator.reducer';
import formFieldValueValidator from 'app/entities/form-field-value-validator/form-field-value-validator.reducer';
import formSubmissionIntegrationType from 'app/entities/form-submission-integration-type/form-submission-integration-type.reducer';
import valueSuggestor from 'app/entities/value-suggestor/value-suggestor.reducer';
import formSubmissionInvite from 'app/entities/form-submission-invite/form-submission-invite.reducer';
import formSubmissionIntegration from 'app/entities/form-submission-integration/form-submission-integration.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  formSubmission,
  form,
  formSection,
  formField,
  formFieldSubmissionValue,
  formSectionField,
  valueValidator,
  formFieldValueValidator,
  formSubmissionIntegrationType,
  valueSuggestor,
  formSubmissionInvite,
  formSubmissionIntegration,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
