import { IValueValidator } from 'app/shared/model/value-validator.model';
import { IFormField } from 'app/shared/model/form-field.model';

export interface IFormFieldValueValidator {
  id?: number;
  validatorInputValues?: string | null;
  valueValidator?: IValueValidator | null;
  formField?: IFormField | null;
}

export const defaultValue: Readonly<IFormFieldValueValidator> = {};
