import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormSubmissionIntegrationType from './form-submission-integration-type';
import FormSubmissionIntegrationTypeDetail from './form-submission-integration-type-detail';
import FormSubmissionIntegrationTypeUpdate from './form-submission-integration-type-update';
import FormSubmissionIntegrationTypeDeleteDialog from './form-submission-integration-type-delete-dialog';

const FormSubmissionIntegrationTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FormSubmissionIntegrationType />} />
    <Route path="new" element={<FormSubmissionIntegrationTypeUpdate />} />
    <Route path=":id">
      <Route index element={<FormSubmissionIntegrationTypeDetail />} />
      <Route path="edit" element={<FormSubmissionIntegrationTypeUpdate />} />
      <Route path="delete" element={<FormSubmissionIntegrationTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormSubmissionIntegrationTypeRoutes;
