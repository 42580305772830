import React, { useEffect, useId, useRef, useState } from 'react';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, Card, Col, Container, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import { createEntity, createForm, sendData, formFill } from './dashboard.reducer';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { IFormField } from 'app/shared/model/form-field.model';
import PendingFieldModal from './pending-field-modal';
import { CustomSpeechForm } from "../../../../content/js/common"

const Dashboard = () => {
    useEffect(() => {
        setTimeout(() => {
            CustomSpeechForm()
        }, 2000)
    }, []);

    return (
        <>
            <form >
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                    />
                </label>
                <br />
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
            <form >
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
        </>
    );
};

export default Dashboard;
