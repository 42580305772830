import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormSubmission from './form-submission';
import Form from './form';
import FormSection from './form-section';
import FormField from './form-field';
import FormFieldSubmissionValue from './form-field-submission-value';
import FormSectionField from './form-section-field';
import ValueValidator from './value-validator';
import FormFieldValueValidator from './form-field-value-validator';
import FormSubmissionIntegrationType from './form-submission-integration-type';
import ValueSuggestor from './value-suggestor';
import FormSubmissionInvite from './form-submission-invite';
import FormSubmissionIntegration from './form-submission-integration';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="form-submission/*" element={<FormSubmission />} />
        <Route path="form/*" element={<Form />} />
        <Route path="form-section/*" element={<FormSection />} />
        <Route path="form-field/*" element={<FormField />} />
        <Route path="form-field-submission-value/*" element={<FormFieldSubmissionValue />} />
        <Route path="form-section-field/*" element={<FormSectionField />} />
        <Route path="value-validator/*" element={<ValueValidator />} />
        <Route path="form-field-value-validator/*" element={<FormFieldValueValidator />} />
        <Route path="form-submission-integration-type/*" element={<FormSubmissionIntegrationType />} />
        <Route path="value-suggestor/*" element={<ValueSuggestor />} />
        <Route path="form-submission-invite/*" element={<FormSubmissionInvite />} />
        <Route path="form-submission-integration/*" element={<FormSubmissionIntegration />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
