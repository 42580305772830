import { IFormField } from 'app/shared/model/form-field.model';
import { IForm } from 'app/shared/model/form.model';

export interface IFormSectionField {
  id?: number;
  sectionFieldOrder?: number | null;
  title?: string | null;
  formFields?: IFormField[] | null;
  form?: IForm | null;
}

export const defaultValue: Readonly<IFormSectionField> = {};
