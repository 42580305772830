import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ValueValidator from './value-validator';
import ValueValidatorDetail from './value-validator-detail';
import ValueValidatorUpdate from './value-validator-update';
import ValueValidatorDeleteDialog from './value-validator-delete-dialog';

const ValueValidatorRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ValueValidator />} />
    <Route path="new" element={<ValueValidatorUpdate />} />
    <Route path=":id">
      <Route index element={<ValueValidatorDetail />} />
      <Route path="edit" element={<ValueValidatorUpdate />} />
      <Route path="delete" element={<ValueValidatorDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ValueValidatorRoutes;
