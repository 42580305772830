import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/form-submission">
        <Translate contentKey="global.menu.entities.formSubmission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form">
        <Translate contentKey="global.menu.entities.form" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-section">
        <Translate contentKey="global.menu.entities.formSection" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-field">
        <Translate contentKey="global.menu.entities.formField" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-field-submission-value">
        <Translate contentKey="global.menu.entities.formFieldSubmissionValue" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-section-field">
        <Translate contentKey="global.menu.entities.formSectionField" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/value-validator">
        <Translate contentKey="global.menu.entities.valueValidator" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-field-value-validator">
        <Translate contentKey="global.menu.entities.formFieldValueValidator" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-submission-integration-type">
        <Translate contentKey="global.menu.entities.formSubmissionIntegrationType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/value-suggestor">
        <Translate contentKey="global.menu.entities.valueSuggestor" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-submission-invite">
        <Translate contentKey="global.menu.entities.formSubmissionInvite" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/form-submission-integration">
        <Translate contentKey="global.menu.entities.formSubmissionIntegration" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
