import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './form-field.reducer';

export const FormFieldDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const formFieldEntity = useAppSelector(state => state.formField.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="formFieldDetailsHeading">
          <Translate contentKey="coreAiFormWizardApp.formField.detail.title">FormField</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.id}</dd>
          <dt>
            <span id="prompt">
              <Translate contentKey="coreAiFormWizardApp.formField.prompt">Prompt</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.prompt}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="coreAiFormWizardApp.formField.name">Name</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.name}</dd>
          <dt>
            <span id="validatorInput">
              <Translate contentKey="coreAiFormWizardApp.formField.validatorInput">Validator Input</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.validatorInput}</dd>
          <dt>
            <span id="suggestorInput">
              <Translate contentKey="coreAiFormWizardApp.formField.suggestorInput">Suggestor Input</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.suggestorInput}</dd>
          <dt>
            <span id="isRequired">
              <Translate contentKey="coreAiFormWizardApp.formField.isRequired">Is Required</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.isRequired ? 'true' : 'false'}</dd>
          <dt>
            <span id="possibleValues">
              <Translate contentKey="coreAiFormWizardApp.formField.possibleValues">Possible Values</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.possibleValues}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="coreAiFormWizardApp.formField.type">Type</Translate>
            </span>
          </dt>
          <dd>{formFieldEntity.type}</dd>
          <dt>
            <Translate contentKey="coreAiFormWizardApp.formField.form">Form</Translate>
          </dt>
          <dd>{formFieldEntity.form ? formFieldEntity.form.id : ''}</dd>
          <dt>
            <Translate contentKey="coreAiFormWizardApp.formField.formSectionField">Form Section Field</Translate>
          </dt>
          <dd>{formFieldEntity.formSectionField ? formFieldEntity.formSectionField.id : ''}</dd>
          <dt>
            <Translate contentKey="coreAiFormWizardApp.formField.formSection">Form Section</Translate>
          </dt>
          <dd>{formFieldEntity.formSection ? formFieldEntity.formSection.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/form-field" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/form-field/${formFieldEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default FormFieldDetail;
