import { IFormSection } from 'app/shared/model/form-section.model';
import { IFormField } from 'app/shared/model/form-field.model';
import { IFormSubmission } from 'app/shared/model/form-submission.model';
import { IFormFieldSubmissionValue } from 'app/shared/model/form-field-submission-value.model';
import { IFormSectionField } from 'app/shared/model/form-section-field.model';
import { FormStatus } from 'app/shared/model/enumerations/form-status.model';
import { FormWizardType } from 'app/shared/model/enumerations/form-wizard-type.model';

export interface IForm {
  id?: number;
  title?: string | null;
  description?: string | null;
  status?: FormStatus | null;
  wizardType?: FormWizardType | null;
  formSections?: IFormSection[] | null;
  formFields?: IFormField[] | null;
  formSubmissions?: IFormSubmission[] | null;
  formFieldSubmissionValues?: IFormFieldSubmissionValue[] | null;
  formSectionFields?: IFormSectionField[] | null;
}

export const defaultValue: Readonly<IForm> = {};
