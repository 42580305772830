import { IFormField } from 'app/shared/model/form-field.model';
import { IForm } from 'app/shared/model/form.model';

export interface IFormSection {
  id?: number;
  title?: string | null;
  description?: string | null;
  sectionOrder?: number | null;
  formFields?: IFormField[] | null;
  form?: IForm | null;
}

export const defaultValue: Readonly<IFormSection> = {};
