import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IFormField } from 'app/shared/model/form-field.model';
import { getEntities as getFormFields } from 'app/entities/form-field/form-field.reducer';
import { IForm } from 'app/shared/model/form.model';
import { getEntities as getForms } from 'app/entities/form/form.reducer';
import { IFormSubmission } from 'app/shared/model/form-submission.model';
import { getEntities as getFormSubmissions } from 'app/entities/form-submission/form-submission.reducer';
import { IFormFieldSubmissionValue } from 'app/shared/model/form-field-submission-value.model';
import { getEntity, updateEntity, createEntity, reset } from './form-field-submission-value.reducer';

export const FormFieldSubmissionValueUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const formFields = useAppSelector(state => state.formField.entities);
  const forms = useAppSelector(state => state.form.entities);
  const formSubmissions = useAppSelector(state => state.formSubmission.entities);
  const formFieldSubmissionValueEntity = useAppSelector(state => state.formFieldSubmissionValue.entity);
  const loading = useAppSelector(state => state.formFieldSubmissionValue.loading);
  const updating = useAppSelector(state => state.formFieldSubmissionValue.updating);
  const updateSuccess = useAppSelector(state => state.formFieldSubmissionValue.updateSuccess);

  const handleClose = () => {
    navigate('/form-field-submission-value' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getFormFields({}));
    dispatch(getForms({}));
    dispatch(getFormSubmissions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...formFieldSubmissionValueEntity,
      ...values,
      formField: formFields.find(it => it.id.toString() === values.formField.toString()),
      form: forms.find(it => it.id.toString() === values.form.toString()),
      formSubmission: formSubmissions.find(it => it.id.toString() === values.formSubmission.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...formFieldSubmissionValueEntity,
          formField: formFieldSubmissionValueEntity?.formField?.id,
          form: formFieldSubmissionValueEntity?.form?.id,
          formSubmission: formFieldSubmissionValueEntity?.formSubmission?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="coreAiFormWizardApp.formFieldSubmissionValue.home.createOrEditLabel"
            data-cy="FormFieldSubmissionValueCreateUpdateHeading"
          >
            <Translate contentKey="coreAiFormWizardApp.formFieldSubmissionValue.home.createOrEditLabel">
              Create or edit a FormFieldSubmissionValue
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="form-field-submission-value-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('coreAiFormWizardApp.formFieldSubmissionValue.fieldName')}
                id="form-field-submission-value-fieldName"
                name="fieldName"
                data-cy="fieldName"
                type="text"
              />
              <ValidatedField
                label={translate('coreAiFormWizardApp.formFieldSubmissionValue.value')}
                id="form-field-submission-value-value"
                name="value"
                data-cy="value"
                type="text"
              />
              <ValidatedField
                id="form-field-submission-value-formField"
                name="formField"
                data-cy="formField"
                label={translate('coreAiFormWizardApp.formFieldSubmissionValue.formField')}
                type="select"
              >
                <option value="" key="0" />
                {formFields
                  ? formFields.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="form-field-submission-value-form"
                name="form"
                data-cy="form"
                label={translate('coreAiFormWizardApp.formFieldSubmissionValue.form')}
                type="select"
              >
                <option value="" key="0" />
                {forms
                  ? forms.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="form-field-submission-value-formSubmission"
                name="formSubmission"
                data-cy="formSubmission"
                label={translate('coreAiFormWizardApp.formFieldSubmissionValue.formSubmission')}
                type="select"
              >
                <option value="" key="0" />
                {formSubmissions && formSubmissions.length > 0
                  ? formSubmissions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/form-field-submission-value" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default FormFieldSubmissionValueUpdate;
