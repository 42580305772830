import dayjs from 'dayjs';
import { IFormSubmissionInvite } from 'app/shared/model/form-submission-invite.model';
import { IFormFieldSubmissionValue } from 'app/shared/model/form-field-submission-value.model';
import { IForm } from 'app/shared/model/form.model';
import { FormSubmissionStatus } from 'app/shared/model/enumerations/form-submission-status.model';

export interface IFormSubmission {
  id?: number;
  status?: FormSubmissionStatus | null;
  completionTimestamp?: string | null;
  nonce?: string | null;
  formSubmissionInvites?: IFormSubmissionInvite[] | null;
  formFieldSubmissionValues?: IFormFieldSubmissionValue[] | null;
  form?: IForm | null;
}

export const defaultValue: Readonly<IFormSubmission> = {};
