import React, { useEffect, useId, useRef, useState } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, CardFooter, Spinner } from 'reactstrap';
import { createEntity, createForm, sendData, formFill } from './dashboard.reducer';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IFormField } from 'app/shared/model/form-field.model';

const FormSection = ({ section, id, promptId, loading, formTitle, fields, setFields, saveEntity }) => {
  console.log(section, loading, 'sectionsection');
  const dispatch = useAppDispatch();

  const { handleStep, previousStep, nextStep, isLastStep } = useWizard();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [sectionData, setSectionData] = useState({});

  const [resultValue, setResultValue] = useState('');

  useEffect(() => {
    let tempData = {};
    section.formFields.map(item => {
      tempData[item.name] = null;
    });
    setSectionData({ ...tempData });
  }, [section]);

  const startListening = () => SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
  const { transcript, interimTranscript, finalTranscript, resetTranscript, listening, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  console.log(useSpeechRecognition(), 'useSpeechRecognition()useSpeechRecognition()useSpeechRecognition()');

  useEffect(() => {
    setResultValue(transcript);
  }, [transcript]);

  useEffect(() => {
    if (resultValue.toLowerCase().includes('upload file')) {
      openFileInput();
    }
  }, [resultValue]);

  useEffect(() => {
    console.log(`%c Browser is ${listening ? 'Listening' : 'not Listening'}`, 'color:red; font-size:20px');
  }, [listening]);

  if (!browserSupportsSpeechRecognition) {
    console.log('Browser not supporting!');
    // return null;
  }

  const openFileInput = () => {
    console.log(document.getElementById('form').querySelector("[type='file']"));

    const fileInput = document.getElementById('form').querySelector("[type='file']");

    let element: HTMLElement = document.getElementById('form').querySelector("[type='file']") as HTMLElement;
    element.click();

    // fileInput.click();
  };

  const convertToTitleCase = inputString => {
    let words = inputString.split(/(?=[A-Z])/);
    let titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let titleCaseString = titleCaseWords.join(' ');
    return titleCaseString;
  };

  // Getting data from backend on the basis of prompt and set pending fields for modal ------ Start
  const resultHandle = async () => {
    setButtonLoading(true);
    await dispatch(sendData({ userPrompt: resultValue, promptId: promptId })).then(({ payload }) => {
      let tempData = sectionData;
      Object.keys(tempData).forEach(item => {
        payload.formFieldSubmissionValues.forEach(data => {
          if (item == data.fieldName) {
            tempData[item] = data.value;
          }
        });
      });
      setFields(prevValue => {
        return {
          ...prevValue,
          ...tempData,
        };
      });
      setButtonLoading(false);
    });
  };
  // Getting data from backend on the basis of prompt and set pending fields for modal ------ End

  const resultValueHandle = e => {
    setResultValue(e.target.value);
  };

  return (
    <>
      <h2>Good Morning</h2>
      <h4>Welcome to {convertToTitleCase(formTitle)}</h4>
      <ValidatedForm onSubmit={resultHandle}>
        <ValidatedField
          label="Result"
          id={`${id}-result`}
          disabled={listening}
          name="result"
          data-cy="title"
          onChange={resultValueHandle}
          value={resultValue}
          type="textarea"
        />
        <Button
          color="info"
          disabled={listening || buttonLoading}
          onClick={() => {
            // setListening(true);
            setResultValue('');
            resetTranscript();
            startListening();
          }}
        >
          {buttonLoading ? <Spinner size="sm">Loading...</Spinner> : ''}
          <span>Start Listening</span>
        </Button>{' '}
        <Button
          disabled={buttonLoading}
          color="warning"
          onClick={() => {
            // setListening(false);
            SpeechRecognition.stopListening();
            resultHandle();
          }}
        >
          {buttonLoading ? <Spinner size="sm">Loading...</Spinner> : ''}
          <span>Stop Listening</span>
        </Button>{' '}
        <Button color="primary" id="result-entity" data-cy="entityCreateSaveButton" disabled={buttonLoading} type="submit">
          {buttonLoading ? <Spinner size="sm">Loading...</Spinner> : ''}

          <span> Submit</span>
        </Button>
      </ValidatedForm>
      <div className="mt-5">
        {loading ? (
          <>
            <Spinner
              color="primary"
              style={{
                height: '3rem',
                width: '3rem',
              }}
            >
              Loading...
            </Spinner>
          </>
        ) : (
          <>
            <h4 className="mb-3">{section.title}</h4>
            {section && section.formFields.length && section.formFields.length > 0 ? (
              <ValidatedForm onSubmit={() => {}} className="row" id="form">
                {section.formFields.map((item: IFormField, index) => {
                  return (
                    <ValidatedField
                      className="col-6"
                      key={index}
                      value={fields[item.name]}
                      invalid={fields[item.name] == '' ? true : false}
                      label={convertToTitleCase(item.name)}
                      id={`${id}-${item.name}`}
                      //   onChange={handleChange}
                      name={item.name}
                      data-cy="title"
                      type="text"
                      required={item.isRequired}
                    />
                  );
                })}
                <ValidatedField
                  className="col-6"
                  label={convertToTitleCase('file')}
                  //   onChange={handleChange}
                  name={'file'}
                  data-cy="title"
                  type="file"
                />
              </ValidatedForm>
            ) : (
              ''
            )}
          </>
        )}
      </div>

      <CardFooter>
        <Button onClick={() => previousStep()}>Previous</Button>{' '}
        <Button
          color="primary"
          onClick={() => {
            console.log(isLastStep, 'isLastStep');
            if (isLastStep) {
              saveEntity();
            } else {
              nextStep();
            }
          }}
        >
          {isLastStep ? 'Submit' : 'Next'}
        </Button>
      </CardFooter>
    </>
  );
};

export default FormSection;
