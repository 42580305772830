import React, { useEffect, useId, useState } from 'react';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Form } from 'reactstrap';

const PendingFieldModal = props => {
  console.log(props, 'propsprops');

  const [loading, setLoading] = useState(true);
  const id = useId();
  const [steps, setSteps] = useState([...props.steps]);
  const [modalOpen, setModalOpen] = useState();
  const [activeStep, setActiveStep] = useState(props.steps[0]);
  useEffect(() => {
    setModalOpen(props.modalOpen);
    if (props.modalOpen) {
      console.log(props.pendingFields, 'pendingFieldspendingFields');
      if (props.pendingFields && props.pendingFields.length > 0) {
        setActiveStep(props.steps[0]);
        setSteps([...props.steps]);
        setLoading(false);
      }
    }
  }, [props.modalOpen]);

  useEffect(() => {
    console.log(steps, activeStep, 'steps,activeStepsteps,activeStep');
  }, [steps, activeStep]);

  const handleNext = dsds => {
    console.log('Enter');

    if (steps[steps.length - 1].key === activeStep.key) {
      alert('You have completed all steps.');
      return;
    }

    const index = steps.findIndex(x => x.key === activeStep.key);
    setSteps(prevStep =>
      prevStep.map(x => {
        if (x.key === activeStep.key) x.isDone = true;
        return x;
      })
    );
    setActiveStep(steps[index + 1]);
  };
  const handleBack = () => {
    const index = steps.findIndex(x => x.key === activeStep.key);
    if (index === 0) return;

    setSteps(prevStep =>
      prevStep.map(x => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };
  return (
    <>
      <Modal isOpen={modalOpen} toggle={props.modalHandle}>
        <ModalHeader toggle={props.modalHandle}>Please fill Pending Fields</ModalHeader>
        <ModalBody>
          <ValidatedForm
            onSubmit={() => {
              console.log('Enter');
            }}
          >
            {activeStep && activeStep.component && activeStep?.component}
            {loading && steps.length == 0 ? (
              ''
            ) : (
              <>
                <Button disabled={steps[0].key === activeStep.key} onClick={handleBack}>
                  Previous
                </Button>{' '}
                {steps[steps.length - 1].key !== activeStep.key ? (
                  <Button color="primary" onClick={handleNext}>
                    Next
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => {
                      props.formSubmitHandle(props.popUpFieldsValues);
                      props.modalHandle();
                    }}
                  >
                    Submit
                  </Button>
                )}
              </>
            )}
          </ValidatedForm>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};
export default PendingFieldModal;
