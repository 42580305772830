import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormFieldSubmissionValue from './form-field-submission-value';
import FormFieldSubmissionValueDetail from './form-field-submission-value-detail';
import FormFieldSubmissionValueUpdate from './form-field-submission-value-update';
import FormFieldSubmissionValueDeleteDialog from './form-field-submission-value-delete-dialog';

const FormFieldSubmissionValueRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FormFieldSubmissionValue />} />
    <Route path="new" element={<FormFieldSubmissionValueUpdate />} />
    <Route path=":id">
      <Route index element={<FormFieldSubmissionValueDetail />} />
      <Route path="edit" element={<FormFieldSubmissionValueUpdate />} />
      <Route path="delete" element={<FormFieldSubmissionValueDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormFieldSubmissionValueRoutes;
