import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormSubmission from './form-submission';
import FormSubmissionDetail from './form-submission-detail';
import FormSubmissionUpdate from './form-submission-update';
import FormSubmissionDeleteDialog from './form-submission-delete-dialog';

const FormSubmissionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FormSubmission />} />
    <Route path="new" element={<FormSubmissionUpdate />} />
    <Route path=":id">
      <Route index element={<FormSubmissionDetail />} />
      <Route path="edit" element={<FormSubmissionUpdate />} />
      <Route path="delete" element={<FormSubmissionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormSubmissionRoutes;
