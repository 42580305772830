import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FormSubmissionIntegration from './form-submission-integration';
import FormSubmissionIntegrationDetail from './form-submission-integration-detail';
import FormSubmissionIntegrationUpdate from './form-submission-integration-update';
import FormSubmissionIntegrationDeleteDialog from './form-submission-integration-delete-dialog';

const FormSubmissionIntegrationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<FormSubmissionIntegration />} />
    <Route path="new" element={<FormSubmissionIntegrationUpdate />} />
    <Route path=":id">
      <Route index element={<FormSubmissionIntegrationDetail />} />
      <Route path="edit" element={<FormSubmissionIntegrationUpdate />} />
      <Route path="delete" element={<FormSubmissionIntegrationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default FormSubmissionIntegrationRoutes;
