import { IFormField } from 'app/shared/model/form-field.model';
import { IForm } from 'app/shared/model/form.model';
import { IFormSubmission } from 'app/shared/model/form-submission.model';

export interface IFormFieldSubmissionValue {
  id?: number;
  fieldName?: string | null;
  value?: string | null;
  formField?: IFormField | null;
  form?: IForm | null;
  formSubmission?: IFormSubmission | null;
}

export const defaultValue: Readonly<IFormFieldSubmissionValue> = {};
