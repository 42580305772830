import { IFormFieldSubmissionValue } from 'app/shared/model/form-field-submission-value.model';
import { IFormFieldValueValidator } from 'app/shared/model/form-field-value-validator.model';
import { IForm } from 'app/shared/model/form.model';
import { IFormSectionField } from 'app/shared/model/form-section-field.model';
import { IFormSection } from 'app/shared/model/form-section.model';

export interface IFormField {
  id?: number;
  prompt?: string | null;
  name?: string | null;
  validatorInput?: string | null;
  suggestorInput?: string | null;
  isRequired?: boolean | null;
  possibleValues?: string | null;
  type?: string | null;
  formFieldSubmissionValues?: IFormFieldSubmissionValue[] | null;
  formFieldValueValidators?: IFormFieldValueValidator[] | null;
  form?: IForm | null;
  formSectionField?: IFormSectionField | null;
  formSection?: IFormSection | null;
}

export const defaultValue: Readonly<IFormField> = {
  isRequired: false,
};
